import { jwtDecode } from 'jwt-decode'
import { Tokens, JwtTokenPayload, StoredUser } from '@authentication/types'
import { getTimestampInSeconds } from '../../utils/helper'
import { defaultLogger } from '@utils/logger'

const TOKEN_EXPIRY_THRESHOLD_SECONDS = 5 * 60 // 5 minutes
export const USER_STORAGE_KEY = 'user'

export interface IUserStorage {
  /**
   * Saves the provided user to the local storage by replacing the existing one.
   *
   * @param email User email.
   * @param tokens Tokens object.
   */
  save(email: string, tokens: Tokens): void
  /**
   * Retrieves the user from the local storage if found.
   */
  get(): StoredUser | undefined
  /**
   * Removes all the user-associated data from local storage.
   */
  clear(): void
  /**
   * Replaces tokens for the stored user.
   *
   * @param tokens Tokens object.
   */
  setTokens(tokens: Tokens): void
  /**
   * Return Authoirzation header payload with a token from user found in localStorage.
   *
   * @returns Authorization header payload or `undefined`.
   */
  getBearer(): string | undefined
  /**
   * Returns refresh token from the user object found in localStorage.
   *
   * @returns Refresh token or `undefined`.
   */
  getRefresh(): string | undefined
  /**
   * Checks if JWT access token has expired by retrieving the stored user from localStorage.
   * If no user stored – returns `true`.
   *
   * **Important:**
   * To avoid token expiration while requests are in-flight the token will be considered expired 5 minutes before actual expiration time.
   *
   * @returns Boolean specifying if the user has expired.
   */
  isExpired(): boolean
}

export const userStorage: IUserStorage = {
  save(email, tokens) {
    const decodedToken = jwtDecode<JwtTokenPayload>(tokens.access_token)
    const user: StoredUser = {
      email: email,
      accessToken: tokens.access_token,
      refreshToken: tokens.refresh_token,
      lastRefresh: getTimestampInSeconds(),
      expiresAt: decodedToken.exp
    }

    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user))
  },

  get() {
    try {
      const storedUser = localStorage.getItem(USER_STORAGE_KEY)

      if (!storedUser) {
        return
      }

      return JSON.parse(storedUser)
    } catch (error: unknown) {
      defaultLogger.error('Failed to parse user from localStorage', {
        level: 'medium',
        error
      })
    }
  },

  clear() {
    localStorage.removeItem(USER_STORAGE_KEY)
  },

  setTokens(tokens) {
    const user = userStorage.get()
    if (user) userStorage.save(user.email, tokens)
  },

  getBearer() {
    const user = userStorage.get()
    return user ? `Bearer ${user.accessToken}` : undefined
  },

  getRefresh() {
    const user = userStorage.get()
    return user?.refreshToken
  },

  isExpired() {
    const user = userStorage.get()

    if (!user) {
      return true
    }

    const nowSeconds = getTimestampInSeconds()

    return user.expiresAt - nowSeconds <= TOKEN_EXPIRY_THRESHOLD_SECONDS
  }
}
