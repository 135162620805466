import { DevLogger } from './devLogger'
import { RootLogger } from './rootLogger'
import { SentryLogger, type SentryHelpers } from './sentryLogger'
import type {
  ILogContext,
  IRootLogger,
  ILogger,
  IRootLogContext
} from './types'

/**
 * Creates a `RootLogger` instance with the provided name.
 * Includes DevLogger by default.
 *
 * @param name Name of the logger.
 * @returns Root logger instance.
 */
function createLogger(name: ILogContext['name']): IRootLogger {
  const rootLogger = new RootLogger({ name })

  if (import.meta.env.DEV) {
    rootLogger.add(new DevLogger())
  }

  return rootLogger
}

const defaultLogger = createLogger('mysaiva-core-app')

export { SentryLogger, createLogger, defaultLogger }

export type {
  ILogger,
  ILogContext,
  IRootLogger,
  IRootLogContext,
  SentryHelpers
}
