import type {
  ILogContext,
  ILogger,
  IRootLogContext,
  IRootLogger,
  LogLevel
} from './types'

export class RootLogger implements IRootLogger {
  private readonly loggers: ILogger[] = []
  private readonly defaultLogContext: ILogContext

  constructor(defaultLogContext: ILogContext) {
    this.defaultLogContext = defaultLogContext
  }

  info(message: string, context?: IRootLogContext): void {
    this.log('info', message, this.getContext(context))
  }

  warning(message: string, context?: IRootLogContext): void {
    this.log('warning', message, this.getContext(context))
  }

  error(message: string, context?: IRootLogContext): void {
    this.log('error', message, this.getContext(context))
  }

  add(logger: ILogger): void {
    this.loggers.push(logger)
  }

  remove(logger: ILogger): void {
    const loggerIdx = this.loggers.findIndex((l) => l == logger)

    if (loggerIdx == -1) {
      console.warn(
        `Unable to remove logger: ${logger} as it is not present in the loggers`
      )
      return
    }

    this.loggers.splice(loggerIdx, 1)
  }

  private log(level: LogLevel, message: string, context?: ILogContext) {
    for (let i = 0; i < this.loggers.length; i++) {
      this.loggers[i][level]?.(message, context)
    }
  }

  private getContext(context?: IRootLogContext): ILogContext {
    if (context) {
      return { ...this.defaultLogContext, ...context }
    }

    return this.defaultLogContext
  }
}
