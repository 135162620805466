import axios, {
  HttpStatusCode,
  isAxiosError,
  type InternalAxiosRequestConfig
} from 'axios'
import { userStorage } from '../utils/local-storage'
import { defaultLogger, SentryHelpers, SentryLogger } from '@utils/logger'
import { ApiError } from './ApiError'

const REQUSET_TIMEOUT = 60_000

let tokenRefreshPromise: Promise<boolean> | undefined

const sentry: SentryHelpers = {
  captureException: () => '',
  captureMessage: () => ''
}

const apiClient = axios.create({
  baseURL: import.meta.env.VITE_REACT_APP_SAIVA_BACKEND_URL,
  withCredentials: false,
  timeout: REQUSET_TIMEOUT,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: userStorage.getBearer()
  }
})

// #region Request interceptors
const requestInterceptors = apiClient.interceptors.request
/**
 * Important: REQUEST interceptors must be registered in the reversed order of execution.
 * This is not applicable to RESPONSE intereceptors, it's just a quirk of Axios.
 */
requestInterceptors.use(addAuthorization)
requestInterceptors.use(waitForToken)
requestInterceptors.use(refreshToken)
requestInterceptors.use(logRequest)

/**
 * Logs request using application logger.
 *
 * @param config Axios request config.
 * @returns Axios request config.
 */
function logRequest(
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig {
  defaultLogger.info(`${config.method?.toUpperCase()} ${config.url}`)
  return config
}

/**
 * Attempts to perform a token refresh request if the token is expired.
 * If refresh token is not found – immediately rejects `tokenRefreshPromise` which will make
 * `waitForToken` interceptor to throw an `Error`.
 *
 * Does nothing if the token refresh is already in progress.
 * This function will ignore all requests with url starting with `/v2/auth/`.
 *
 * @param config Axios request config.
 * @returns Axios request config.
 */
function refreshToken(
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig {
  if (
    isRefreshingToken() ||
    isAuthURL(config.url) ||
    !userStorage.isExpired()
  ) {
    return config
  }

  tokenRefreshPromise = getTokenRefreshPromise()

  return config
}

/**
 * Blocks requests while the token refresh is in progress.
 * If the token refresh fails – throws an `Error` and rejects any pending requests.
 *
 * This function will not block requests with url starting with `/v2/auth/`.
 *
 * @param config Axios request config.
 * @returns Axios request config.
 */
async function waitForToken(
  config: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> {
  if (!isRefreshingToken() || isAuthURL(config.url)) {
    return config
  }

  const isRefreshed = await tokenRefreshPromise
  tokenRefreshPromise = undefined

  if (isRefreshed) {
    return config
  }

  throw new Error('Token refresh error')
}

/**
 * Adds `Authorization` header to each request.
 *
 * @param config Axios request config.
 * @returns Axios request config.
 */
function addAuthorization(
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig {
  config.headers.Authorization = userStorage.getBearer()

  return config
}
// #endregion

// #region Response interceptors
apiClient.interceptors.response.use(null, handleFailedRequest)

async function handleFailedRequest(error: unknown) {
  const sentryLogger = new SentryLogger(sentry)
  if (isAxiosError(error)) {
    if (error.response) {
      switch (error.response.status) {
        case HttpStatusCode.Unauthorized:
          refresh()
          break

        case HttpStatusCode.Forbidden:
          // logout()
          sentryLogger.error('Forbidden request', {
            name: 'API client error',
            error
          })
          break

        default:
          defaultLogger.error('API client error', { error })
          sentryLogger.error('API client error', {
            name: 'API client error',
            error
          })
      }
    }
  }
  return Promise.reject(new ApiError(error))
}

//  #endregion

// #region Utils
function isAuthURL(url?: string): boolean {
  return Boolean(
    url?.startsWith('v2/auth/') || url?.startsWith('v2/invitations/')
  )
}

function isRefreshingToken() {
  return !!tokenRefreshPromise
}

function getTokenRefreshPromise(): Promise<boolean> {
  return new Promise(function tryRefreshToken(resolve) {
    const refreshToken = userStorage.getRefresh()

    if (!refreshToken) {
      resolve(false)
      return
    }

    apiClient
      .post('v2/auth/jwt/refresh', { refresh_token: refreshToken })
      .then(function onRefreshSuccess(response) {
        userStorage.setTokens(response.data)
        resolve(true)
      })
      .catch(function onRefreshError(error) {
        logout()

        defaultLogger.error('Failed to refresh access token', {
          level: 'medium',
          error
        })

        resolve(false)
      })
  })
}
// #endregion

function logout() {
  localStorage.clear()
  window.location.replace('/login')
}

function refresh() {
  if (!isRefreshingToken() && !userStorage.isExpired()) {
    tokenRefreshPromise = getTokenRefreshPromise()
  }
}

export { apiClient, refresh }
