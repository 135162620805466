import { isAxiosError } from 'axios'
import { i18next } from '../../i18n/config'

export class ApiError extends Error {
  public source: unknown

  constructor(source: unknown, message?: string) {
    super(getErrorMessage(source, message))
    this.source = source
  }

  public get localizedError(): string {
    const unknownError = i18next.t('common.errors.unknown')

    if (isAxiosError(this.source)) {
      const code = this.source.response?.data?.code

      if (typeof code == 'string' && code.trim().length > 0) {
        const key = `backendErrors.${code}`

        return i18next.t(key, unknownError)
      }
    }

    return unknownError
  }
}

function getErrorMessage(source: unknown, message?: string): string {
  return message ?? (source as Error)?.message ?? 'UnknownError'
}
