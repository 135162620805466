import * as SentryReact from '@sentry/react'
import { ILogContext, ILogger } from './types'

/**
 * Object containing subset of sentry/react methods used to capture exceptions/messages.
 */
export type SentryHelpers = Pick<
  typeof SentryReact,
  'captureException' | 'captureMessage'
>

export class SentryLogger implements ILogger {
  private readonly sentryHelpers: SentryHelpers

  constructor(sentry: SentryHelpers) {
    this.sentryHelpers = sentry
  }

  error(message: string, context?: ILogContext): void {
    if (context?.level != 'high') {
      return
    }

    if (context?.error instanceof Error) {
      this.sentryHelpers.captureException(context.error)
    } else {
      this.sentryHelpers.captureMessage(message)
    }
  }
}
